<template>
  <div class="deploy">
    deploy
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    
  },
  data() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
  .deploy {
    
  }
</style>
